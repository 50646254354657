import React, { useState, useEffect, useLayoutEffect } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCategories, fetchShopBycategoryList, updateShopByCategory, deleteShopByCategoryImage, fetchBrands } from '../store/actions/services';
import { fetchProducts } from '../store/actions/product';

import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';



export default function EditPopularCategoriesPage(props) {

  const [values, setValues] = useState({
    Id: '',
    Name: '',
    Image: '',
    Status: '',
    ScreenName: '',
    Parameters: '',
    HeadingText: '',
    dropdownArray: [],
  });

  const [errorMsg, setErrorMsg] = useState(null);
  const [dialogOpen, setdialog] = useState(false);

  const brands = useSelector(state => (state.services.brands || []));
  const isBRDFetching = useSelector(state => (state.services.isBRDFetching));
  const categories = useSelector(state => (state.services.categories || []));
  const products = useSelector(state => (state.product.products || []));


  const isPopBrandAdding = useSelector(state => (state.services.isPopBrandAdding));
  const isPopBrandAdded = useSelector(state => (state.services.isPopBrandAdded));

  // const mount = () => {
  //   dispatch(fetchBrands());
  // };
  // // eslint-disable-next-line
  // useEffect(mount, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();


  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    setErrorMsg(null);
  };

  // const handlechangedropdown = (selected, state) => {
  //   setValues({ ...values, [state]: selected !== null ? selected.value : '' });
  //   setErrorMsg(null);
  // }

  const handlechangedropdown = (selected, state) => {
    var dropdownArray = [];

    if (state === "ScreenName" && selected ? selected.value === "productdetail" : null) {
        {
            (products || []).map((s) => {
                dropdownArray.push({ label: s.Name, value: s.Id })
            })
        }
        setValues({
            ...values,
            HeadingText: "Select Product",
            dropdownArray: dropdownArray,
            [state]: selected !== null ? selected.value : '',
            Parameters: ''
        });
    } else if (state === "ScreenName" && selected ? selected.value === "brand" : null) {
        {
            (brands || []).map((s) => {
                dropdownArray.push({ label: s.Name, value: s.Id })
            })
        }
        setValues({
            ...values,
            HeadingText: "Select Brand",
            dropdownArray: dropdownArray,
            [state]: selected !== null ? selected.value : '',
            Parameters: ''
        });
    } else if (state === "ScreenName" && selected ? selected.value === "category" || "products" : null) {
        {
            (categories || []).filter(s => s.ParentCategoryId === 0).map((s) => {
                dropdownArray.push({ label: s.Name, value: s.Id })
            })
        }

        setValues({
            ...values,
            HeadingText: "Select Category",
            dropdownArray: dropdownArray,
            [state]: selected !== null ? selected.value : '',
            Parameters: ''
        });
    }

    else

        setValues({ ...values, [state]: selected !== null ? selected.value : '' });

    setErrorMsg(null);

}

var catArray = [];
var prodArray = [];
// var BrandArray = [];
if (location.state.BannerDetail) {
  // console.log("enter")
  {
    (products || []).map((s) => {
      prodArray.push({ label: s.Name, value: s.Id })
    })
  }
  {
    (categories || []).filter(s => s.ParentCategoryId === 0).map((s) => {
      catArray.push({ label: s.Name, value: s.Id })
    })
  }
  // {
  //   (brands || []).map((s) => {
  //     BrandArray.push({ label: s.Name, value: s.Id })
  //   })
  // }
}
  
  useEffect(() => {
    dispatch(fetchProducts({}));
    dispatch(fetchBrands());
    dispatch(fetchCategories());

    if (location.state && location.state.shopByCategoryDetail) {
      setValues({
        Id: (location.state.shopByCategoryDetail && location.state.shopByCategoryDetail.Id) || "",
        Name: (location.state.shopByCategoryDetail && location.state.shopByCategoryDetail.Name) || "",
        Image: (location.state.shopByCategoryDetail && location.state.shopByCategoryDetail.Image) || "",
        Status: (location.state.shopByCategoryDetail && location.state.shopByCategoryDetail.Status) || "",
        ScreenName: (location.state.shopByCategoryDetail && location.state.shopByCategoryDetail.ScreenName) || "",
        Parameters: (location.state.shopByCategoryDetail && location.state.shopByCategoryDetail.Parameters) || "",
      
        HeadingText: ((location.state.shopByCategoryDetail && 
          location.state.shopByCategoryDetail.ScreenName && 
          location.state.shopByCategoryDetail.ScreenName === "category"  || 
          location.state.shopByCategoryDetail.ScreenName === "products") ? "Select Category" : "Select Product"),
        dropdownArray: ((location.state.shopByCategoryDetail && 
          location.state.shopByCategoryDetail.ScreenName && 
          location.state.shopByCategoryDetail.ScreenName === "category" || 
          location.state.shopByCategoryDetail.ScreenName === "products") ? catArray : prodArray),
      });

    }
  }, [location]);

  console.log("shop by cat detail", values.HeadingText);

  const isInputValid = () => {
    let isInputValid = true;
    let errorMsg = "";

    if (values.Name === "") {
      isInputValid = false;
      errorMsg = "Please Enter Client Name.";
    } else if (values.Image === "") {
      isInputValid = false;
      errorMsg = "Please Select Category's Image.";
    }
    setErrorMsg(errorMsg);

    return isInputValid;
  }

  const hiddenFileInput = React.useRef(null);


  const handleUploadImgBtn = () => {
    //method to Link FileInput element
    hiddenFileInput.current.click();
  };

  const handleAddImg = (event) => {

    let files = event.target.files;
    let reader = new FileReader();
    reader.readAsDataURL(files[0]);

    reader.onload = (e) => {
      setValues({ ...values, Image: e.target.result });
    }
  };

  const handleDeleteimg = (catId) => {
    dispatch(deleteShopByCategoryImage(catId)).then(r => dispatch(fetchShopBycategoryList()));
    setValues({ ...values, Image: "" });
    setdialog(false);
  };

  const handleSubmit = () => {
    if (isInputValid()) {
      var UpdatedCategoryParams;
      if (document.getElementById("CatImgFInput").files.length === 0) {
        UpdatedCategoryParams = {
          Id: values.Id,
          Name: values.Name,
          Status: values.Status
        };

        dispatch(updateShopByCategory(UpdatedCategoryParams)).then(r => dispatch(fetchShopBycategoryList()));
        navigate("/popularCategories");
      }
      else {
        UpdatedCategoryParams = {
          Id: values.Id,
          Name: values.Name,
          Image: values.Image,
          Status: values.Status
        };

        dispatch(updateShopByCategory(UpdatedCategoryParams)).then(r => dispatch(fetchShopBycategoryList()));
        navigate("/popularCategories");
      }

    }
  }

  const StatusArray = [
    { label: "Active", value: "Active" },
    { label: "In-Active", value: "InActive" },
  ];

  
  const ScreenNames = [
    { label: "Products", value: "products" },
    { label: "Product Detail", value: "productdetail" },
    { label: "Category", value: "category" },
    { label: "Brand", value: "brand" },
];


  return (
    <div>
      <Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CardHeader title="Edit Shop by Category" />
          <div
            style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "auto", marginRight: 18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/popularCategories") }}>Cancel</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
          <div>
            <Card>
              <CardContent sx={{ p: 5 }} style={{ minHeight: 600 }}>
                <Grid container spacing={3}>
                  <Grid container xs={12} mb={6} alignItems="center" justifyContent="center" flexDirection="column" >
                    <Typography variant="h5" >Client Image</Typography>
                    <Typography variant="subtitle1" >Image should be (512 x 512px)</Typography>

                    <div className='imageuploadbox'>

                      {(values.Image) && (
                        <div style={{ position: "relative", padding: 5, }}>
                          <IconButton
                            style={{ position: "absolute", right: 2, top: 2 }}
                            sx={{ backgroundColor: "white" }}
                            onClick={() => setdialog(true)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                          <img src={values.Image} width={210} height={125} style={{ alignSelf: "center", borderRadius: 15, objectFit: "contain" }} />
                        </div>
                      )}
                    </div>
                    {(!values.Image) && (
                      <Button style={{ marginTop: 14 }} variant='contained' color="primary" onClick={() => handleUploadImgBtn()}>
                        Upload Image
                      </Button>
                    )}
                    <input ref={hiddenFileInput} style={{ display: 'none' }} id="CatImgFInput" className="choosefile-input" type="file" onChange={handleAddImg} />

                    <Dialog open={dialogOpen} onClose={() => setdialog(false)} >
                      <DialogTitle >
                        {"Delete Image"}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText >
                          Are you sure you wish to delete this Category Image ?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => handleDeleteimg(values.Id)}>Yes</Button>
                        <Button onClick={() => setdialog(false)} autoFocus>
                          No
                        </Button>
                      </DialogActions>
                    </Dialog>

                  </Grid>

                  <Grid item xs={6} >
                    <TextField fullWidth label="Category Name" value={values.Name} name="Name" onChange={handleChange} />
                  </Grid>


                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Navigation Screen Name"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                          menuList: (base) => ({
                            ...base,/*  height: 150 */
                          }),
                        }}
                        value={ScreenNames.filter(p => p.value === values.ScreenName)}

                        isClearable={true}
                        isSearchable={true}
                        onChange={(selected) => handlechangedropdown(selected, "ScreenName")}
                        options={ScreenNames}
                      />
                    </FormControl>
                  </Grid>



                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Status"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                        }}
                        value={StatusArray.filter(s => s.value === values.Status)}
                        // isLoading={true}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selected) => handlechangedropdown(selected, "Status")}
                        options={StatusArray}
                      />
                    </FormControl>
                  </Grid>

                  {values.ScreenName !== "" ?
                    <Grid item xs={6} >
                      <FormControl required sx={{ width: "100%" }}>
                        <Select
                          className="basic-single"
                          placeholder={values.HeadingText}
                          styles={{
                            placeholder: (base) => ({
                              ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                            }),
                            /* For multivalues */
                            valueContainer: (base) => ({
                              ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                            }),

                            singleValue: (base) => ({
                              ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                            }),
                            menuList: (base) => ({
                              ...base, height: 150
                            }),
                          }}
                          isLoading={isBRDFetching}
                          isClearable={true}
                          isSearchable={true}
                          onChange={(selected) => handlechangedropdown(selected, "Parameters")}
                          options={values.dropdownArray}
                        />
                      </FormControl>
                    </Grid>
                    : null}

                </Grid>
              </CardContent>

              <Divider />

              <div style={{ textAlign: "center", padding: 16 }}>
                {(errorMsg && errorMsg !== "") && (
                  <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                )}
                <Button variant='contained' color='secondary' onClick={handleSubmit} >Update</Button>
              </div>
            </Card>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}