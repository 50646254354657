import React ,{ useState, useEffect, useLayoutEffect  } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { fetchServiceLocations, updateServiceLocation, /*  GetStateList, */ GetCityList} from '../store/actions/addresses';
import { useLocation, useNavigate } from 'react-router-dom';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export default function EditServiceLocationPage(props) {
  
  const [values, setValues] = useState({
    Id:'',
    Area:'',
    CityId:'',
    // State: '',
    // IsDefault: false, 

  });

  // const isdefaultvalues = [
  //   {Id: 1, value:"true", Label:"Yes"},
  //   {Id: 2, value:"false", Label: "No"}
  // ]

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState(null);

  const isCityListFatching = useSelector(state => (state.addresses.isCityListFatching || false));
  const CityList = useSelector(state => (state.addresses.CityList || []));

  // const isStateListFatching = useSelector(state => (state.addresses.isStateListFatching || false));
  // const StateList = useSelector(state => (state.addresses.StateList || []));

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const handlechangedropdown = (selected, state) => {
    setValues({ ...values, [state]: selected !== null ? selected.value : '' });
    setErrorMsg(null);
  }

  const location = useLocation();

  const mount = () => {
    dispatch(fetchServiceLocations(location.state.serviceLocations));
    dispatch(GetCityList());
    // dispatch(GetStateList());
  }

  console.log("location. serive location");
  console.log(location.state.serviceLocation);
  // eslint-disable-next-line
  useLayoutEffect(mount, []);

  useEffect( () =>{
    
    if(location.state && location.state.serviceLocation){
      setValues({
        Id: (location.state.serviceLocation && location.state.serviceLocation.Id) || "",
        Area: (location.state.serviceLocation && location.state.serviceLocation.Area) || null,
        CityId: (location.state.serviceLocation && location.state.serviceLocation.CityId) || "",
        // State: (location.state.serviceLocation && location.state.serviceLocation.State) || "",
        // IsDefault: (location.state.serviceLocation && location.state.serviceLocation.IsDefault) || "", 
      });
    }
  }, [location]);

  const isInputValid = () => {
    let isInputValid = true;
    let errorMsg = "";

    if(values.Area === ""){
      isInputValid = false;
      errorMsg = "Please Enter Area Name.";
    }else if (values.CityId === ""){
      isInputValid = false;
      errorMsg = "Please Select City Name.";
    }
    setErrorMsg( errorMsg );

    return isInputValid ;
  }

  const handleSubmit = () => {
    
    if(isInputValid()){
      var UpdatedServiceLocParams = {
        Id: values.Id,
        Area: values.Area,
        CityId: '' + values.CityId,
        // State: values.State,
        IsDefault: values.IsDefault,
        // Status: "Active",
      };
      // console.log("updated values:",UpdatedServiceLocParams )
  
      dispatch(updateServiceLocation(UpdatedServiceLocParams)).then(r => dispatch(fetchServiceLocations()));
      navigate("/serviceLocations");
    }
  }

  const CitiesArray = CityList.map((c, i) => {
    return ({
      label: c.Name,
      value: c.Id
    })
  });

 /*  const StateArray = StateList.map((s, i) => {
    return ({
      label: s.Name,
      value: s.Id
    })
  }); */

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Edit Service Location" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/serviceLocations") }}>Cancel</Button>
          </div>
        </div>
        <Divider />
        <CardContent>
          <div>
            <Card>
              <CardContent sx={{p: 5}}>
                <Grid container spacing={3}>
                  
                <Grid item xs={6} >
                    <TextField /* required */ fullWidth label="Area" value={values.Area} name="Area" onChange={handleChange} />
                  </Grid>
                  
                  
                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Select City"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                        }}
                        value = {CitiesArray.filter(c => c.value === values.CityId)}
                        isLoading={isCityListFatching}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selected) => handlechangedropdown(selected, "CityId")}
                        options={CitiesArray}
                      />
                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Select State"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          // For multivalues
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                        }}
                        isLoading={isStateListFatching}
                        isClearable={true}
                        isSearchable={true}
                        // onChange={(selected) => handlechangedropdown(selected, "ProductId")}
                        options={StateArray}
                      />
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={6} style={{marginTop:5}} >
                    <FormGroup aria-label="position" row >
                      <FormControlLabel control={<Checkbox defaultChecked={false} checked={values.IsDefault} onChange={(e) => { setValues({...values, IsDefault: e.target.checked}) }} />} 
                        labelPlacement="start" 
                        label="Check to set it as Default"
                      />
                    </FormGroup>
                  </Grid>

                </Grid>
              </CardContent>
              
              <Divider />
              
              <div style={{textAlign: "center", padding: 16}}>
                {(errorMsg && errorMsg !== "") && (
                  <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                )}
                <Button variant='contained' color='secondary' onClick={handleSubmit} >Save</Button>
              </div>
            </Card>
          </div>
        </CardContent>       
      </Card>
    </div>
  );
}