import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import AnalyticsIcon from '@mui/icons-material/Analytics';
import CategoryIcon from '@mui/icons-material/Category';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonIcon from '@mui/icons-material/Person';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';

const drawerWidth = 250;

function SideMenu() {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: 'auto' }}>
        <List>
          <ListItem>
            <ListItemButton to="/">
              <ListItemIcon>
                <DashboardCustomizeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton to="/cityList">
              <ListItemIcon>
                <HouseOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Cities"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/stateList">
              <ListItemIcon>
                <HomeWorkOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"States"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/postalCodes">
              <ListItemIcon>
                <ApprovalOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Postal Codes"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/serviceLocations">
              <ListItemIcon>
                <RoomOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Service Locations"} />
            </ListItemButton>
          </ListItem>
          {/*  <ListItem>
            <ListItemButton to="/pickupLocations">
              <ListItemIcon>
                <RoomOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Pickup Locations"} />              
            </ListItemButton>
          </ListItem> */}
          <Divider />
          <ListItem>
            <ListItemButton to="/products">
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary={"Products"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/productsPrice">
              <ListItemIcon>
                <CurrencyRupeeIcon />
              </ListItemIcon>
              <ListItemText primary={"Products Price"} />
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton to="/primarybanners">
              <ListItemIcon>
                <ViewCarouselOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Primary Banners"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/secondarybanners">
              <ListItemIcon>
                <ViewCarouselOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Secondary Banners"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/properties">
              <ListItemIcon>
                <MapsHomeWorkOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"properties"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/professionals">
              <ListItemIcon>
                <PersonSearchOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"professionals"} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton to="/categories">
              <ListItemIcon>
                <CategoryIcon />
              </ListItemIcon>
              <ListItemText primary={"Categories"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/productTypes">
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary={"Product Type"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/brands">
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary={"Brands"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/ourClients">
              <ListItemIcon>
                <GroupAddOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary={"Our Clients"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/popularCategories">
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary={"Shop by Category"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/popularBrands">
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary={"Shop by Brands"} />
            </ListItemButton>
          </ListItem>

          <ListItem>
            <ListItemButton to="/recommendedProducts">
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary={"Recommended Products"} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton to="/vendors">
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={"Vendors"} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton to="/customers">
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={"Customers"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/quotations">
              <ListItemIcon>
                <ShoppingBagIcon />
              </ListItemIcon>
              <ListItemText primary={"Quotations"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/orders">
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary={"Orders"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/deliveryRequest">
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary={"Delivery Request"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/invoice">
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary={"Invoice/Billing"} />
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton to="/deliveryChallan">
              <ListItemIcon>
                <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary={"Delivery Challan"} />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemButton to="/notification">
              <ListItemIcon>
                <NotificationsActiveIcon />
              </ListItemIcon>
              <ListItemText primary={"Notification"} />
            </ListItemButton>
          </ListItem>
          {/* <ListItem>
            <ListItemButton to="/productSize">
              <ListItemIcon>
                <InventoryIcon />
              </ListItemIcon>
              <ListItemText primary={"Product Size"} />              
            </ListItemButton>
          </ListItem> */}
        </List>
        <Divider />
      </Box>
    </Drawer>
  );
}

export default SideMenu;