import React, { useState, useEffect, } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';

import { fetchServiceLocations, fetchPostalCode, addPostalCode } from '../store/actions/addresses';

import "../css/addproductpage.css";

export default function AddPostalCodePage(props) {

  const [values, setValues] = useState({
    Postcode: '',
    ServiceLocId: '',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMsg, setErrorMsg] = useState(null);
  const isSERLOCFetching = useSelector(state => (state.addresses.isSERLOCFetching || false));
  const serviceLocations = useSelector(state => (state.addresses.serviceLocations || []));

  const mount = () => {
    dispatch(fetchServiceLocations())
  }

  // eslint-disable-next-line
  useEffect(mount, []);

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const handlechangedropdown = (selected, state) => {
    console.log("selected", selected)
    setValues({ ...values, [state]: selected !== null ? selected.value : '' });
    setErrorMsg(null);
  }

  const isInputValid = () => {
    let isInputValid = true;
    let errorMsg = "";
    const ALPHA_NUMERIC = /^[a-zA-Z]+$/;


    if (values.Postcode === "") {
      isInputValid = false;
      errorMsg = "Please Enter Postal Code.";
    } else if(values.Postcode.length > 6){
      isInputValid = false;
      errorMsg = "Postal code should be 6 characters .";
    } else if (ALPHA_NUMERIC.test(values.Postcode)){
      isInputValid = false;
      errorMsg = "Postal code should be numbers .";
    } else if (values.ServiceLocId === "") {
      isInputValid = false;
      errorMsg = "Please Select Service Location.";
    }
    setErrorMsg(errorMsg);
    return isInputValid;
  }

  const handleSubmit = () => {
    if (isInputValid()) {

      var AddPostalCodeParams = {
        Postcode: values.Postcode,
        ServiceLocationId: values.ServiceLocId,
      };

      dispatch(addPostalCode(AddPostalCodeParams)).then(r => dispatch(fetchPostalCode({})));
      navigate("/postalCodes");
    }
  }

  const ServiceLocationArray = serviceLocations.map((s, i) => {
    return ({
      label: s.Area + ", " + s.City + "," + s.State,
      value: s.Id
    })
  });

  return (
    <div>
      <Card>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CardHeader title="Add Postal Code" />
          <div
            style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "auto", marginRight: 18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/postalCodes") }}>Cancel</Button>
          </div>
        </div>

        <Divider />

        <CardContent>
          <div>
            <Card>
              <CardContent sx={{ p: 5 }} style={{minHeight:200}}>
                <Grid container spacing={3}>

                  <Grid item xs={6} >
                    <TextField 
                      fullWidth 
                      label="Postal Code" 
                      value={values.Postcode} 
                      name="Postcode" 
                      onChange={handleChange} 
                      inputProps={{
                        maxLength: 6,
                      }}
                    type='number'  
                    />
                  </Grid>

                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <Select
                        className="basic-single"
                        placeholder={"Select Service Location"}
                        styles={{
                          placeholder: (base) => ({
                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                          }),
                          /* For multivalues */
                          valueContainer: (base) => ({
                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                          }),

                          singleValue: (base) => ({
                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                          }),
                          menuList:(base) => ({
                            ...base, height:150
                          }),
                        }}
                        isLoading={isSERLOCFetching}
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selected) => handlechangedropdown(selected, "ServiceLocId")}
                        options={ServiceLocationArray}
                      />
                    </FormControl>
                  </Grid>

                </Grid>
              </CardContent>

              <Divider/>

              <div style={{ textAlign: "center", padding: 16 }}>
                {(errorMsg && errorMsg !== "") && (
                  <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                )}
                <Button variant='contained' color='secondary' onClick={handleSubmit} >Add</Button>
              </div>
            </Card>
          </div>
        </CardContent>
      </Card>
    </div>

  );
}