import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { fetchPrimaryBanners, deleteBanner } from '../store/actions/services'; 
import { useNavigate } from 'react-router-dom';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import Tooltip from '@mui/material/Tooltip';


export default function PrimeryBannersPage(props) {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageSize, setPageSize] = useState(20);
  const [dialogOpen, setdialog] = useState(false);
  const [deleteBannerId, setdeleteBannerId] = useState("");
  const primaryBanners = useSelector(state => (state.services.primaryBanners || []));
  const isPOFFetching = useSelector(state => (state.services.isPOFFetching ));
  // const isPOFFetched = useSelector(state => (state.services.isPOFFetched ));

  const convert = (str) => {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [ day, mnth, date.getFullYear()].join("-");
  }
  
  const mount = () => {
    dispatch(fetchPrimaryBanners());
  }
  useEffect(mount, []);

  const handleEditClick = (id) => {
    var fltr_PBanner = Object.assign({}, ...primaryBanners.filter(c => { if(c.Id === id) return c; }));
    navigate("/editbanner", { state: { BannerDetail: fltr_PBanner, IsPrimary: true }} );
  };

  const handleDeleteClick = (BannerId) => {
    dispatch(deleteBanner(BannerId)).then(r => dispatch(fetchPrimaryBanners()));
    setdialog(false);
  };

  // const handleCellClick = (params) => {
  //   if(params.field !== "actions"){
  //     navigate("/viewCustomer", { state: { customer: params.id }} );
  //   }
  // };

  const columns = [
    { field: 'id', headerName: 'S.No', align: "left", flex:0.5 },
    { field: 'image', headerName: 'Image', align: "left", flex:1, 
     renderCell: (params)=>{
      return (
        <div>
          <img src={params.row.image} alt='BannerImg' width="100" height="42" style={{ borderRadius: 5, objectFit:"cover"}} />
        </div>
      )}
    },
    // { field: 'tital', headerName: 'Tital', align: "left", flex:0.7 },
    { field: 'navigationscreenname', headerName: 'Navigation Screen Name', align: "left", flex:1.1 },
    { field: 'startdate', headerName: 'StartDate', align: "left", flex:0.7 },
    { field: 'enddate', headerName: 'EndDate', align: "left", flex:0.7 },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 0.5,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <Tooltip title="Edit">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(params.id)}
              color="inherit"
            />
          </Tooltip>,
          <div>
            <Tooltip title="Delete">
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => { 
                  // console.log("params", params)
                  setdeleteBannerId(params.id);
                  setdialog(true);
                }}
                color="inherit"
              />
            </Tooltip>
            <Dialog open={dialogOpen} style={{ opacity:"0.3" }} onClose={() => setdialog(false)} > 
              <DialogTitle >
                {"Delete Banner"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText >
                  Are you sure you wish to delete this Primary Banner ?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleDeleteClick(deleteBannerId)}>Yes</Button>
                <Button onClick={() => setdialog(false)} autoFocus>
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>,
        ];
      }
    }
  ];

  const rows = primaryBanners.map(b => {
    return ({ 
      id: b.Id,
      image: b.Image, 
      // tital:b.Text1, 
      navigationscreenname: b.ScreenName,
      // subtitaltext: b.Text2,
      startdate: convert(b.StartDate),
      enddate: convert(b.EndDate)
    })
  });

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Primary Banners" />
          <Button 
            style={{ display:"flex",
              flexDirection:"row",
              alignItems:"center",
              justifyContent:"center",
              marginLeft:"auto",
              marginRight:18
            }}
            onClick={() => { navigate("/addbanner", { state: { IsPrimary: true }} )}}  
          >
            <AddCircleIcon />
            <Typography component={"span"} style={{ marginLeft:4 }}>Add Primary Banner</Typography>
          </Button>
        </div>
        
        <Divider />
        
        {primaryBanners && (
          <CardContent>
            <DataGrid
              rows={rows}
              columns={columns}
              loading={ isPOFFetching ? true : false }
              autoHeight={18}
              pageSize={pageSize}
              // onCellClick={handleCellClick}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </CardContent>
        )}
        
      </Card>
    </div>
  );
}