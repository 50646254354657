import React ,{ useState, useEffect, } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import IconButton from '@mui/material/IconButton';
import ImageListItemBar from '@mui/material/ImageListItemBar';
// import CancelIcon from '@mui/icons-material/Cancel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import { useNavigate } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { fetchProductTypes, fetchBrands, fetchProductSizes } from '../store/actions/services';
import { fetchServiceLocations } from '../store/actions/addresses';
import { addProduct, fetchProducts } from '../store/actions/product';
import { RupeeSymbol } from '../components/util';
// import Box from '@mui/material/Box';
// import Chip from '@mui/material/Chip';

import "../css/addproductpage.css";


export default function AddProductPage(props) {

  const [values, setValues] = useState({
    Bannerimg: [],
    Smallimg: '', 
    Name: '', 
    BrandId: '', 
    ListPrice: '', 
    OriginalPrice: '', 
    ProductTypeId: '',
    Description:'',
    Size: '',
    Information: '',
    Features: '',
    Disclaimer: '',
    DelInstruction: '',
    TaxAmount: '',
    Offertxt: '',
    PackingCharge: '',
    DelCharge: '',
    DelDays: '',
    MinimumOrderQuantity: '',
    MinimumBulkOrderQuantity: '',
    VehicleCapacity: '',
    GST:''
    //ServiceLocId: []
  });

  console.log("Smallimg", values.Smallimg);
  
  const [errorMsg, setErrorMsg] = useState(null);
  // const [invalidFieldName, setinvalidFieldName] = useState(null);
  
  const dispatch = useDispatch();
  const productTypes = useSelector(state => (state.services.productTypes || {}));
  const brands = useSelector(state => (state.services.brands || {}));
  const productSizes = useSelector(state => (state.services.productSizes || {}));
  // const serviceLocations = useSelector(state => (state.addresses.serviceLocations || []));
  const isPRDAdding = useSelector(state => (state.product.isPRDAdding));
  // var smallimgs = [];
  // var bannerimgs = [];

  const MenuProps = {
    style: {
      maxHeight: 300,
    }
  };
  
  const navigate = useNavigate();

  const mount = () => {
    dispatch(fetchProductTypes());
    dispatch(fetchBrands());
    dispatch(fetchProductSizes());
    dispatch(fetchServiceLocations());
  }
  
  // eslint-disable-next-line
  //useLayoutEffect(mount, []);

  useEffect( mount, []);

  const handleChange = (event) => {
    setValues({...values, [event.target.name]: event.target.value});
    setErrorMsg(null);
  };

  const hiddenFileInput1 = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);

  const handleUploadImgBtn = (fieldname) => {
    //method to Link FileInput element
    console.log("upload call", fieldname);
    console.log("inputt", hiddenFileInput2);
    
    if(fieldname === "BannerImg"){
      hiddenFileInput1.current.click();
      // console.log("inputt22", hiddenFileInput1);

    }else{
      hiddenFileInput2.current.click();
      console.log("inputt22", hiddenFileInput2);

    }
  };

  const handleAddImg = (imgname) => (event) =>{
    console.log("callinggggg", event)
    if(imgname === "bannerimg"){
      // var img = event.target.files[0];
      // console.log("normal banner img")
      // console.log(img);

      let files = event.target.files;
      let reader = new FileReader();
      
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        /* if(values.Bannerimg.length < 5 ){ */
          var imgarray = [].concat(values.Bannerimg).concat([e.target.result]);
          setValues({ ...values, Bannerimg: imgarray });
        /* }  */
      }
    }
    else if(imgname === "smallimg"){
      //var img = event.target.files[0];
      let files = event.target.files;
      let reader = new FileReader();
      
      reader.readAsDataURL(files[0]);
      reader.onload = (e) => {
        setValues({ ...values, Smallimg: e.target.result });
      }
    }
  }

  const removeBannerImg = (i) => {
    var fltr_bannerimg = values.Bannerimg.filter((item, index) => index !== i );
    setValues({ ...values, Bannerimg: fltr_bannerimg });
  }

  const handleSelectChange = (fieldname) => (event) => {
    if(fieldname === "ProductType"){
      setValues({ ...values, ProductTypeId: event.target.value });
    }
    if(fieldname === "Brand"){
      setValues({ ...values, BrandId: event.target.value });
    }
    if(fieldname === "Size"){
      setValues({ ...values, Size: event.target.value });
    }
    if(fieldname === "ServiceLocId"){
      setValues({ ...values, ServiceLocId: event.target.value });
    }
  };

  const isInputValid = () => {
    let isInputValid = true;
    let invalidFieldName = "";
    let errorMsg = "";

    if(!values.Bannerimg.length || values.Smallimg === "" || values.Name === "" || values.ListPrice === "" || values.OriginalPrice === "" ||
    values.ProductTypeId === "" || values.Size === "" || values.Description === "" || values.DelDays === "" /*|| values.ServiceLocId.length == 0*/ /* ||
    values.MinimumOrderQuantity === "" */ /* || values.MinimumBulkOrderQuantity === "" */){
      isInputValid = false;
      errorMsg = "Please fill all the * marked fields.";
      // invalidFieldName = "BannerImg";
    }
    else if(parseInt(values.ListPrice) > parseInt(values.OriginalPrice)){
      isInputValid = false;
      errorMsg = "Original Price should be more than List Price.";
    }
    setErrorMsg( errorMsg );
    //setinvalidFieldName( invalidFieldName );

    return isInputValid ;
  }

  const handleSubmit = () => {
    //handle submit btn
    if(isInputValid()){

      var product = {
        BannerImage: values.Bannerimg,
        SmallImage: values.Smallimg,
        Name: values.Name,
        BrandId: values.BrandId,
        ListPrice: values.ListPrice,
        OriginalPrice: values.OriginalPrice,
        ProductTypeId: values.ProductTypeId,
        Description: values.Description,
        Size: values.Size,
        Information: values.Information,
        Features: values.Features,
        Disclaimer: values.Disclaimer,
        DeliveryInstruction: values.DelInstruction,
        OfferText: values.Offertxt,
        TaxAmount: values.TaxAmount,
        PackingCharge: values.PackingCharge, 
        DeliveryCharge: values.DelCharge, 
        DeliveryDays: values.DelDays,
        MinOrderQuantity: values.MinimumOrderQuantity,
        MinBulkOrderQuantity: values.MinimumBulkOrderQuantity,
        VehicleCapacity: values.VehicleCapacity,
        GST: values.GST
        // CanSubscribe: false,
        //ServiceLocationId: values.ServiceLocId
      };

      // console.log("product Detail");
      // console.log(product);
      dispatch(addProduct(product)).then(r => dispatch(fetchProducts({})));
      navigate("/products");
      
    }
  }

  let banner_multiimg = [];
  for (let i = 0; i < 5; i++) {
    banner_multiimg.push(
    <input key={i} className="choosefile-input" type="file" onChange={handleAddImg("bannerimg")} />
    );
  }

  return (    
    <div>
      <Card>
        <div style={{ display:"flex", flexDirection:"row" }}>
          <CardHeader title="Add Product" />
          <div
            style={{ display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"center", marginLeft:"auto", marginRight:18 }}
          >
            <Button variant='contained' color="error" onClick={() => { navigate("/products") }}>Cancel</Button>
          </div>
        </div>
        
        <Divider />
        
        <CardContent>
          <div>
            <Card>
              <CardContent sx={{p: 5}}>
                <Grid container spacing={3}>
                  
                  <Grid container xs={6} mb={6} alignItems="center" justifyContent="center" flexDirection="column" >
                    <Typography variant="h5" >Detail Page Image*</Typography>
                    <Typography variant="subtitle1" >Image should be (1024 x 410px)</Typography>
                                        
                    {(values.Bannerimg && values.Bannerimg.length) ?
                      <ImageList sx={{ width: 500 }} cols={2} rowHeight={164}> 
                        {values.Bannerimg.map((b_img, i) => (
                          <ImageListItem key={i}>
                            <img src={b_img}
                              alt={"Product"}
                              width={300} 
                              height={225}
                              style={{ objectFit: "contain"}}
                              loading="lazy"
                            />
                            <ImageListItemBar sx={{ background:"transparent" }}
                              position="top"
                              actionIcon={
                                <IconButton
                                  sx={{ backgroundColor: "white" }}
                                  onClick={() => removeBannerImg(i)}
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              }
                            />
                          </ImageListItem>
                        ))}
                      </ImageList>
                      : null
                    }
                    {/* {(values.Bannerimg && values.Bannerimg.length < 5) && (  */}
                      {/* This is masked Button for hiddenFileInput */}
                      <Button style={{ marginTop: 14 }} variant='contained' color="primary" onClick={() => handleUploadImgBtn("BannerImg")}>
                        Upload Image
                      </Button>
                      <input ref={hiddenFileInput1} style={{display:'none'}} required className="choosefile-input" type="file" name="Bannerimg" value="" onChange={handleAddImg("bannerimg")} />
                    {/* )} */}
                  </Grid>
                  
                  <Grid container xs={6} mb={6} alignItems="center" justifyContent="center" flexDirection="column" >
                    <Typography variant="h5" >Small Image*</Typography>
                    <Typography variant="subtitle1" >Image should be (512 x 477px)</Typography>
                    
                    {(values.Smallimg) && (
                      <div style={{ position:"relative", marginTop: "12px"}}>
                        <IconButton
                          style={{ position:"absolute", right: 0 }}
                          sx={{ backgroundColor:"white" }}
                          onClick={() => setValues({...values, Smallimg:''})}
                        >
                          <DeleteIcon color="error" />
                        </IconButton>
                        <img src={values.Smallimg} width={175} height={175}  />
                      </div>
                    )}
                    
                    
                    {/* This is masked Button for hiddenFileInput */}
                    {(!values.Smallimg) && (
                      <Button style={{ marginTop: 14 }} variant='contained' color="primary" onClick={() => handleUploadImgBtn("SmallImg")}>
                        Upload Image
                      </Button>
                    )}
                    <input ref={hiddenFileInput2} style={{display:'none'}} required className="choosefile-input" name="Smallimg" type="file" onChange={handleAddImg("smallimg")} />
                  </Grid>

                  <Grid item xs={6} >
                    <TextField required fullWidth label="Name" value={values.Name} name="Name" onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <InputLabel id="demo-controlled-open-select-label">Product Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={values.ProductTypeId}
                        label="Product Type"
                        onChange={handleSelectChange("ProductType")}
                        MenuProps={{
                          style: {
                            maxHeight: 300,
                          },
                        }}
                      >
                        {productTypes.map((pt, i) => {
                          return <MenuItem  value={pt.Id} key={i}>{pt.Name}</MenuItem >
                        })}
                    </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={6} >
                    <TextField required 
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                      }} 
                      fullWidth 
                      label="List Price"
                      type="number" 
                      value={values.ListPrice} 
                      name="ListPrice" 
                      onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={6} >
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-controlled-open-select-label">Brand</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={values.BrandId}
                        label="Brand"
                        onChange={handleSelectChange("Brand")}
                        MenuProps={{
                          style: {
                            maxHeight: 300,
                          },
                        }}
                      >
                        {brands.map((b, i) => {
                          return <MenuItem  value={b.Id} key={i}>{b.Name}</MenuItem >
                        })}
                    </Select>
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={6} >
                    <TextField required 
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                      }}
                      fullWidth 
                      label="Original Price"
                      error={parseInt(values.ListPrice) > parseInt(values.OriginalPrice)}
                      helperText={parseInt(values.ListPrice) > parseInt(values.OriginalPrice) ? "Original Price should be more than List Price." : ""}
                      type="number" 
                      value={values.OriginalPrice} 
                      name="OriginalPrice" 
                      onChange={handleChange} />
                  </Grid>

                  <Grid item xs={6} >
                    <FormControl required sx={{ width: "100%" }}>
                      <InputLabel id="demo-controlled-open-select-label">Size</InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={values.Size}
                        label="Size"
                        onChange={handleSelectChange("Size")}
                        MenuProps={{
                          style: {
                            maxHeight: 300,
                          },
                        }}
                      >
                        {productSizes.map((ps, i) => {
                          return <MenuItem  value={ps} key={i}>{ps}</MenuItem >
                        })}
                      </Select>
                    </FormControl>
                  </Grid>

                  {/* ...................... */}

                  <Grid item xs={6} >
                    <TextField 
                      /* required */ 
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                      }}
                      fullWidth 
                      label="Minimum Order Quantity"
                      // error={parseInt(values.ListPrice) > parseInt(values.OriginalPrice)}
                      // helperText={parseInt(values.ListPrice) > parseInt(values.OriginalPrice) ? "Original Price should be more than List Price." : ""}
                      // type="number" 
                      value={values.MinimumOrderQuantity}
                      name="MinimumOrderQuantity" 
                      onChange={handleChange} />
                  </Grid>

                  <Grid item xs={6} >
                    <TextField  
                      /* required */ 
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                      }}
                      fullWidth 
                      label="Minimum Bulk Order Quantity"
                      // error={parseInt(values.ListPrice) > parseInt(values.OriginalPrice)}
                      // helperText={parseInt(values.ListPrice) > parseInt(values.OriginalPrice) ? "Original Price should be more than List Price." : ""}
                      type="number" 
                      value={values.MinimumBulkOrderQuantity} 
                      name="MinimumBulkOrderQuantity" 
                      onChange={handleChange} />
                  </Grid>

                  <Grid item xs={6} >
                    <TextField  
                      fullWidth 
                      placeholder="Enter comma separated value"
                      label="Vehicle Capacity"
                      // type="number" 
                      value={values.VehicleCapacity} 
                      name="VehicleCapacity" 
                      onChange={handleChange} 
                      />
                  </Grid>

                  <Grid item xs={6} >
                    <TextField 
                      InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                      }} 
                      fullWidth 
                      label="GST"
                      type="number" 
                      value={values.GST} 
                      name="GST" 
                      onChange={handleChange} />
                  </Grid>


                  {/* <Grid item xs={6} >
                        <FormControl required sx={{ width: "100%" }}>
                          <InputLabel id="demo-controlled-open-select-label">Service Locations</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={values.ServiceLocId}
                            defaultValue=""
                            label="Service Locations"
                            onChange={handleSelectChange("ServiceLocId")}
                            renderValue={(selected) => (
                              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => {
                                  var fltr_sl = Object.assign({}, ...serviceLocations.filter(sl => {if(value == sl.Id) return sl;}));
                                  var Area = (fltr_sl.Area) ? fltr_sl.Area+", " : "";
                                  var City = (fltr_sl.City) ? fltr_sl.City+", " : "";
                                  var State = (fltr_sl.State) ? fltr_sl.State : "";
                                  var listitem = Area + City + State;    
                                  return (<Chip key={value} label={listitem} />);
                                })}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {serviceLocations.map((sl, i) => {
                              var Area = (sl.Area) ? sl.Area+", " : "";
                              var City = (sl.City) ? sl.City+", " : "";
                              var State = (sl.State) ? sl.State : "";
                              var listitem = Area + City + State;
                              return (
                                <MenuItem  value={sl.Id} key={i}>
                                  <Checkbox checked={values.ServiceLocId.indexOf(sl.Id) > -1 } />
                                  <ListItemText primary={listitem} />
                                </MenuItem > 
                              )
                            })}
                          </Select>
                        </FormControl>
                      </Grid> */}

                  <Grid item xs={12} >
                    <TextField 
                      fullWidth
                      required
                      multiline
                      label="Description" 
                      //dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + values.Description + "</div>"}}
                      value={values.Description}
                      /* maxRows="Infinity" */
                      minRows="3"
                      name="Description" 
                      onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={12} >
                    <TextField 
                      fullWidth
                      multiline
                      label="Information" 
                      //dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + values.Description + "</div>"}}
                      value={values.Information}
                      /* maxRows="Infinity" */
                      minRows="3"
                      name="Information" 
                      onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={12} >
                    <TextField 
                      fullWidth
                      multiline
                      label="Features" 
                      //dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + values.Description + "</div>"}}
                      value={values.Features}
                      /* maxRows="Infinity" */
                      minRows="3"
                      name="Features" 
                      onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={12} >
                    <TextField 
                      fullWidth
                      multiline
                      label="Disclaimer" 
                      //dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + values.Description + "</div>"}}
                      value={values.Disclaimer}
                      /* maxRows="Infinity" */
                      minRows="3"
                      name="Disclaimer" 
                      onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={12} >
                    <TextField 
                      fullWidth
                      multiline
                      label="Delivery Instruction" 
                      //dangerouslySetInnerHTML={{__html: "<div style='font-size: 14px' >" + values.Description + "</div>"}}
                      value={values.DelInstruction}
                      /* maxRows="Infinity" */
                      minRows="3"
                      name="DelInstruction" 
                      onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={12} >
                    <TextField fullWidth multiline minRows="3" label="Offer Text" value={values.Offertxt} name="Offertxt" onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={6} >
                    <TextField fullWidth 
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                      }}
                      label="Tax Amount" 
                      type="number" 
                      value={values.TaxAmount} 
                      name="TaxAmount" 
                      onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={6} >
                    <TextField fullWidth 
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                      }}
                      label="Packing Charge" 
                      type="number" 
                      value={values.PackingCharge} 
                      name="PackingCharge" 
                      onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={6} >
                    <TextField fullWidth 
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{RupeeSymbol}</InputAdornment>,
                      }}
                      label="Delivery Charge" 
                      type="number" 
                      value={values.DelCharge} 
                      name="DelCharge" 
                      onChange={handleChange} />
                  </Grid>
                  
                  <Grid item xs={6} >
                    <TextField required fullWidth label="Delivery Days" type="number" value={values.DelDays} name="DelDays" onChange={handleChange} />
                  </Grid>

                </Grid>
              </CardContent>
              
              <Divider />
              
              <div style={{textAlign: "center", padding: 16}}>
                {(errorMsg && errorMsg !== "") && (
                  <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                )}
                {(isPRDAdding) ? 
                  <Button variant='contained' color='secondary' >Adding...</Button> :
                  <Button variant='contained' color='secondary' onClick={handleSubmit}>Add</Button>
                }
              </div>
            </Card>
          </div>
        </CardContent>        
      </Card>
    </div>
  );
}