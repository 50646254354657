import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
import Select from 'react-select';
// import Checkbox from '@mui/material/Checkbox';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
// import { useNavigate } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchCategories, fetchBrands, addBanner, fetchPrimaryBanners, fetchSecondaryBanners } from '../store/actions/services';
// import { fetchServiceLocations } from '../store/actions/addresses';
import { fetchProducts } from '../store/actions/product';
import { format } from 'date-fns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { createTheme } from '@mui/material/styles';


export default function AddBannerPage(props) {
    const [values, setValues] = useState({
        Bannerimg: '',
        Title: '',
        SubTitle: '',
        ScreenName: '',
        Parameters: '',
        BrandId: '',
        IsPrimary: '',
        StartDate: '',
        EndDate: '',
        HeadingText: '',
        dropdownArray: []
    });


    const [errorMsg, setErrorMsg] = useState(null);

    const categories = useSelector(state => (state.services.categories || []));
    // const serviceLocations = useSelector(state => (state.addresses.serviceLocations || []));

    const brands = useSelector(state => (state.services.brands || []));
    const isBRDFetching = useSelector(state => (state.services.isBRDFetching));

    const products = useSelector(state => (state.product.products || []));
    // const isPRFetching = useSelector(state => (state.product.isPRFetching));

    const isBannerAdding = useSelector(state => (state.services.isBannerAdding ));
    // const isBannerAdded = useSelector(state => (state.services.isBannerAdded ));


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const mount = () => {
        dispatch(fetchProducts({}));
        dispatch(fetchBrands());
        dispatch(fetchCategories());
        // dispatch(fetchServiceLocations());
    };
    // eslint-disable-next-line
    useEffect(mount, []);

    const handleChange = (event) => {
        setValues({ ...values, [event.target.name]: event.target.value });
        setErrorMsg(null);
    };

    const hiddenFileInput = React.useRef(null);

    const handleUploadImgBtn = () => {
        //method to Link FileInput element
        hiddenFileInput.current.click();
    };

    const handleAddImg = (event) => {

        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);

        reader.onload = (e) => {
            setValues({ ...values, Bannerimg: e.target.result });
        }
    };


    const handlechangedropdown = (selected, state) => {

        var dropdownArray = [];

        if (state === "ScreenName" &&  selected ? selected.value === "productdetail" : null) {
            {(products || []).map((s) => {
                dropdownArray.push({ label: s.Name, value: s.Id })
            })}
            setValues({...values, 
                HeadingText: "Select Product", 
                dropdownArray: dropdownArray, 
                [state]: selected !== null ? selected.value : '', 
                Parameters:'' 
            });           
        }else if (state === "ScreenName" &&  selected ? selected.value === "category" || "products" : null) {
            {(categories || []).filter(s => s.ParentCategoryId === 0).map((s) => {
                dropdownArray.push({ label: s.Name, value: s.Id })
            })}

            setValues({...values,  
                HeadingText: "Select Category", 
                dropdownArray: dropdownArray, 
                [state]: selected !== null ? selected.value : '',
                Parameters:''});
        }else 

            setValues({ ...values, [state]: selected !== null ? selected.value : '' });
        
        setErrorMsg(null);
        
    }
      

    const isInputValid = () => {
        let isInputValid = true;
        let errorMsg = "";

        if (values.Bannerimg === "") {
            isInputValid = false;
            errorMsg = "Please Select Banner Image first."
        } else if (values.Title === "") {
            isInputValid = false;
            errorMsg = "Title Should not be empty."
        } /* else if (values.ScreenName === "") {
            isInputValid = false;
            errorMsg = "Please Select Navigation Screen Name."
        }  */else if (values.StartDate === "") {
            isInputValid = false;
            errorMsg = "Please Enter Start Date."
        } else if (values.EndDate === "") {
            isInputValid = false;
            errorMsg = "Please Enter End Date."
        } /* else if (values.Parameters === "" || values.BrandId === "") {
            isInputValid = false;
            errorMsg = "Parameters Brand must be Selected."
        } */
        setErrorMsg(errorMsg);

        return isInputValid;
    };

    const handleSubmit = () => {
        if (isInputValid()) {
            var AddBannerParams = {
                Image: values.Bannerimg,
                Text1: values.Title,
                Text2: values.SubTitle,
                ScreenName: values.ScreenName,
                Parameters: values.Parameters,
                BrandId: values.BrandId,
                IsPrimary: location.state.IsPrimary,
                // StartDate: values.StartDate,
                // EndDate: values.EndDate

                StartDate: format(values.StartDate.$d, "yyyy-MM-dd HH:mm:ss"),
                EndDate: format(values.EndDate.$d, "yyyy-MM-dd HH:mm:ss"),

            };

            console.log("AddBannerParams");
            console.log(AddBannerParams);
              dispatch(addBanner(AddBannerParams)).then(r => dispatch(location.state.IsPrimary ? fetchPrimaryBanners() : fetchSecondaryBanners()));
              navigate(location.state.IsPrimary ? "/primarybanners" : "/secondarybanners");
        }
    }


    const ScreenNames = [
        { Id: 1, label: "Products", value: "products" },
        { Id: 2, label: "Product Detail", value: "productdetail" },
        { Id: 3, label: "Category", value: "category" },
        { Id: 4, label: "Brand", value: "brand" },
    ];

    const newTheme = (theme) => createTheme({
        ...theme,
        components: {
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        color: '#1565c0',
                        // borderRadius: '7px',
                        borderWidth: '1px',
                        borderColor: '#2196f3',
                        // border: '1px solid',
                        backgroundColor: '#fff',
                    },
                    today: {
                        color: '#1565c0',
                        borderRadius: '7px',
                        borderWidth: '1px',
                        borderColor: '#2196f3',
                        border: '1px solid',
                        backgroundColor: '#fff',
                    },
                },
            },
        },
    });

    const BrandsArray = brands.map((s, i) => {
        return ({
            label: s.Name,
            value: s.Id
        })
    });

    return (
        <div>
            <Card>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <CardHeader title="Add Banner" />
                    <div
                        style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginLeft: "auto", marginRight: 18 }}
                    >
                        <Button variant='contained' color="error" onClick={() => { navigate(location.state.IsPrimary ? "/primarybanners" : "/secondarybanners") }}>Cancel</Button>
                    </div>
                </div>

                <Divider />

                <CardContent sx={{ p: 5 }}>
                    <Grid container spacing={3}>
                        <Grid container item xs={12} mb={6} alignItems="center" justifyContent="center" flexDirection="column" >
                            <Typography variant="h5" >Banner Image*</Typography>
                            <Typography variant="subtitle1" >Image should be (1024 x 410px)</Typography>

                            {(values.Bannerimg) && (
                                <div style={{ position: "relative", marginTop: "12px" }}>
                                    <IconButton
                                        style={{ position: "absolute", right: 0 }}
                                        sx={{ backgroundColor: "white" }}
                                        onClick={() => setValues({ ...values, Bannerimg: '' })}
                                    >
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                    <img src={values.Bannerimg} alt='Banner Image' width={175} height={175} />
                                </div>
                            )}

                            {/* This is masked Button for hiddenFileInput */}
                            {(!values.Bannerimg) && (
                                <Button style={{ marginTop: 14 }} variant='contained' color="primary" onClick={() => handleUploadImgBtn()}>
                                    Upload Image
                                </Button>
                            )}
                            <input ref={hiddenFileInput} style={{ display: 'none' }} className="choosefile-input" type="file" onChange={handleAddImg} />
                        </Grid>

                        <Grid item xs={6} >
                            <TextField required fullWidth label="Title" value={values.Title} name="Title" onChange={handleChange} />
                        </Grid>

                        <Grid item xs={6} >
                            <FormControl required sx={{ width: "100%" }}>
                                <Select
                                    className="basic-single"
                                    placeholder={"Navigation Screen Name"}
                                    styles={{
                                        placeholder: (base) => ({
                                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                                        }),
                                        /* For multivalues */
                                        valueContainer: (base) => ({
                                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                                        }),

                                        singleValue: (base) => ({
                                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                                        }),
                                        menuList: (base) => ({
                                            ...base, height: 150
                                        }),
                                    }}
                                    isLoading={isBRDFetching}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(selected) => handlechangedropdown(selected, "ScreenName")}
                                    options={ScreenNames}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={6} >
                            <TextField /* required */ fullWidth label="Sub Title" value={values.SubTitle} name="SubTitle" onChange={handleChange} />
                        </Grid>

                        {values.ScreenName !== "" && values.ScreenName !== "brand" ?
                            <Grid item xs={6} >
                                <FormControl required sx={{ width: "100%" }}>
                                    <Select
                                        className="basic-single"
                                        placeholder={values.HeadingText}
                                        styles={{
                                            placeholder: (base) => ({
                                                ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                                            }),
                                            /* For multivalues */
                                            valueContainer: (base) => ({
                                                ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                                            }),

                                            singleValue: (base) => ({
                                                ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                                            }),
                                            menuList: (base) => ({
                                                ...base, height: 150
                                            }),
                                        }}
                                        isLoading={isBRDFetching}
                                        isClearable={true}
                                        isSearchable={true}
                                        onChange={(selected) => handlechangedropdown(selected, "Parameters")}
                                        options={values.dropdownArray}
                                    />
                                </FormControl>
                            </Grid>

                            : 
                            <Grid item xs={6} >
                            </Grid>}


                        <Grid item xs={2}/*  spacing={4} */ >
                            <div className="div-infocontainer">
                                <Typography variant="subtitle2">Start Date: </Typography>
                            </div>
                        </Grid>

                        <Grid item xs={4} /* spacing={4} */>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <ThemeProvider theme={newTheme}>
                                    <DatePicker
                                        label="Select Start Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={values.StartDate}
                                        onChange={(newValue) => {
                                            console.log("new values", format(newValue.$d, "dd-MM-yyyy"))
                                            setValues({ ...values, StartDate: newValue })
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </ThemeProvider>
                            </LocalizationProvider>
                        </Grid>

                        {values.ScreenName === "products" || values.ScreenName === "brand" ?
                        <Grid item xs={6} >
                            <FormControl required sx={{ width: "100%" }}>
                                <Select
                                    className="basic-single"
                                    placeholder={"Select Brand"}
                                    styles={{
                                        placeholder: (base) => ({
                                            ...base, fontSize: '14px', color: "#666666", lineHeight: "3.45em"
                                        }),
                                        /* For multivalues */
                                        valueContainer: (base) => ({
                                            ...base, color: '#000000', width: '100%', lineHeight: "2.9em"
                                        }),

                                        singleValue: (base) => ({
                                            ...base, color: "#000000", display: 'flex', lineHeight: "3.45em"
                                        }),
                                        menuList: (base) => ({
                                            ...base, height: 150
                                        }),
                                    }}
                                    isLoading={isBRDFetching}
                                    isClearable={true}
                                    isSearchable={true}
                                    onChange={(selected) => handlechangedropdown(selected, "BrandId")}
                                    options={BrandsArray}
                                />
                            </FormControl>
                        </Grid>
                         : 
                         
                         <Grid item xs={6} >
                            </Grid>}

                        <Grid item xs={2} /* spacing={4} */ >
                            <div className="div-infocontainer">
                                <Typography variant="subtitle2">End Date: </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={4} /* spacing={4} */>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <ThemeProvider theme={newTheme}>
                                    <DatePicker
                                        label="Select End Date"
                                        inputFormat="DD/MM/YYYY"
                                        value={values.EndDate}
                                        onChange={(newValue) => {
                                            console.log("new values", format(newValue.$d, "dd-MM-yyyy"))
                                            setValues({ ...values, EndDate: newValue })
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                    />
                                </ThemeProvider>
                            </LocalizationProvider>
                        </Grid>

                    </Grid>

                </CardContent>

                <Divider />

                <div style={{ textAlign: "center", padding: 16 }}>
                    {(errorMsg && errorMsg !== "") && (
                        <Typography mb={2} variant="body2" color='error' >{errorMsg}</Typography>
                    )}
                    {(isBannerAdding) ?
                        <Button variant='contained' color='secondary' >Adding...</Button> :
                        <Button variant='contained' color='secondary' onClick={handleSubmit}>Add Banner</Button>
                    } 
                </div>

            </Card>
        </div>
    );
}